<template>
	<div class="page">
		<div class="hx_openLicenseItem_box w1239">
			<div class="block30"></div>
			<div class="itemsearch_box">
				<el-form :inline="true" :model="formInline" class="demo-form-inline">
					<el-form-item label="专利开放类型">
						<el-select v-model="formInline.openTypeId" filterable placeholder="可输入关键词搜索">
							<el-option label="全部" :value="null" />
							<el-option v-for="(item, index) in openTypeList" :key="index" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="许可技术类型">
						<el-select v-model="formInline.openTechnologyId" filterable placeholder="可输入关键词搜索">
							<el-option label="全部" :value="null" />
							<el-option v-for="(item, index) in techTypeList" :key="index" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
					<el-form-item label="标题名称">
						<el-input v-model="formInline.title" placeholder="请输入关键词"></el-input>
					</el-form-item>
					<el-form-item label="专利权利人">
						<el-input v-model="formInline.applicant" placeholder="请输入专利权利人"></el-input>
					</el-form-item>
					<el-form-item label="是否免费">
						<el-select v-model="formInline.freeState" placeholder="请选择">
							<el-option label="全部" :value="null" />
							<el-option label="否" :value="0" />
							<el-option label="是" :value="1" />
						</el-select>
					</el-form-item>
					<el-form-item label="发布时间">
					    <el-col :span="11">
					      <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.startReleaseTime" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					    </el-col>
					    <el-col class="line" :span="2" style="text-align: center;">--</el-col>
					    <el-col :span="11">
					      <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endReleaseTime" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd"></el-date-picker>
					    </el-col>
					</el-form-item>
					<el-form-item><el-button type="primary" @click="onSubmit">查询搜索</el-button></el-form-item>
				</el-form>
			</div>
			<!-- 列表 -->
			<div class="licenseItem_box">
				<div class="tb fontsize16">
					<div class="tr tr0"></div>
					<div class="tr tr1">专利号</div>
					<div class="tr tr2">标题名称</div>
					<div class="tr tr3">专利权利人</div>
					<div class="tr tr4">是否免费</div>
					<div class="tr tr5">许可的技术领域</div>
					<div class="tr tr6">许可期限（届满日）</div>
					<div class="tr tr7">许可的地域范围</div>
					<div class="tr tr8">操作</div>
				</div>
				<div class="th fontsize13" v-for="(item,index) in dataList" :key="index">
					<div class="td td0">{{item.index}}</div>
					<div class="td td1">{{item.patentNo}}</div>
					<div class="td td2">{{item.title}}</div>
					<div class="td td3">{{item.applicant}}</div>
					<div class="td td4">{{item.freeState==0?"否":"是"}}</div>
					<div class="td td5">{{item.openTechnologyName}}</div>
					<div class="td td6">{{item.periodDate}}</div>
					<div class="td td7">{{item.openArea}}</div>
					<div class="td td8" @click="$util.routerPath('/openLicenseDetail',{uid:item.id})">
						<div class="text">详情</div>
					</div>
				</div>
				<div class="emptyText fontsize16" v-if="dataList.length<=0">暂无相关记录~</div>
				<div class="block30"></div>
				<div class="w1239 phonenone" style="text-align:right">
				    <el-pagination
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next, jumper"
				          :total="total">
				    </el-pagination>
				</div>
				<div class="w1239 pcnone" style="text-align:center">
				    <el-pagination
					      small
				          @current-change="handleCurrentChange"
				          :current-page.sync="currentPage"
				          :page-size="pageSize"
				          layout="prev, pager, next"
				          :total="total">
				    </el-pagination>
				</div>
				<div class="block20"></div>
			</div>
		</div>
		<div class="block58"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			formInline: {
				openTechnologyId:null,
				openTypeId:null,
				title:null,
				applicant:null,
				freeState:null,
				startReleaseTime:null,
				endReleaseTime:null,
			},
			dataList: [] ,//最新专利
			currentPage:1,
			pageSize:10,
			total:0,
			openTypeList:[],
			techTypeList:[],
		};
	},
	mounted: function() {
		this.getdataList()
		//开放类型
		this.getOpenPatentType()
		//技术类型
		this.getOpenPatentTechnology()
		
	},
	computed: {
		...mapState(['userInfo', 'isLogin'])
	},
	methods: {
		//开放类型
		getOpenPatentType() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888
			};
			this.$http.post('frontEnd/open-patent/getOpenPatentType', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.openTypeList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//技术类型
		getOpenPatentTechnology() {
			var _this = this;
			var params = {
				currentPage: 1,
				pageSize: 888
			};
			this.$http.post('frontEnd/open-patent/getOpenPatentTechnology', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					_this.techTypeList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//条件查询
		onSubmit(){
			this.currentPage = 1
			this.getdataList()
		},
		//跳转页面
		handleCurrentChange(currentPage){
			console.log(currentPage)
			this.currentPage = currentPage
			this.getdataList()
		},
		//获取数据
		getdataList() {
			var _this = this;
			var params = this.formInline
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["useContents"] = false
			this.$http.post("frontEnd/open-patent/getOpenPatentInfo", params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records;
					records.forEach((item,index)=>{
						// if(item.releaseTime){
						// 	item["releaseTime"] = _this.$util.formatdate(_this.$util.getDateTime(item.releaseTime))
						// }
						// item["timer"] = _this.$util.formatdate(_this.$util.getDateTime(item.createDate))
						item["index"] = (_this.currentPage-1)*_this.pageSize + (index+1)
					})
					_this.total = res.data.total
					_this.dataList = records
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		
	}
};
</script>
<style lang="scss"></style>
